<template>
  <div class="auth-wrapper w-100 p-3 mx-auto h-100 justify-content-center align-content-center align-items-center bg-white">
    <b-row style="display: block">
      <div class="d-inline-block d-flex justify-content-center m-3 align-content-center align-items-center">
        <img
          style="width: 90px"
          src="@/assets/images/logo/verification.svg"
        >
      <!-- <verifivcation /> -->
      </div>
      <!-- form -->
      <div class="d-inline-block d-flex justify-content-center align-content-center align-items-center">
        <p class="font-weight-bold heading text-primary">
          Verification
        </p>
      </div>
      <div class="d-inline-block d-flex justify-content-center align-content-center align-items-center">
        <h4 style="font-size: 12px">
          Please enter your mobile number for verification
        </h4>
      </div>
      <validation-observer ref="registerForm">
        <b-form class="auth-register-form m-2">
          <!-- phonenumber -->
          <b-form-group
            label-for="register-phonenumber"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Phonenumber"
              rules="required"
            >
              <b-form-input
                v-model="phonenumber"
                name="Phonenumber"
                placeholder="+923"
                type="text"
                :state="errors.length > 0 ? false:null"
                style="font-size:18px"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <div
            class="form-group block mt-2"
          >
            <b-button
              variant="primary"
              block
              type="submit"
              :disabled="!active"
              @click.prevent="verifyPhone"
            >
              <div
                v-if="spinner"
                class="spinner"
              >
                <b-spinner
                  small
                />
                <span class="sr-only">Loading...</span>
              </div>
              Verify
            </b-button>
          </div>
        <!-- /Register-->
        </b-form>
      </validation-observer>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  BForm, BButton, BFormInput, BRow, BFormGroup, BSpinner,
} from 'bootstrap-vue'
import {
  required, email,
} from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { regex } from 'vee-validate/dist/rules'

import store from '@/store'

extend('regex', regex)
export default {
  components: {
    BForm,
    BButton,
    BFormInput,
    BSpinner,
    // validations
    ValidationProvider,
    ValidationObserver,
    BRow,
    BFormGroup,
  },
  mixins: [togglePasswordVisibility],
  beforeRouteEnter(to, from, next) {
    const user = store.getters['user/getUser']
    if (user.is_phone_number_verified) {
      next({
        name: 'dashboard',
      })
      return
    }
    next()
  },
  data() {
    return {
      status: '',
      phonenumber: '',
      active: true,
      spinner: false,
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    user() {
      return this.$store.getters['user/getUser']
    },
  },
  methods: {
    verifyPhone() {
      this.$refs.registerForm.validate().then(async success => {
        if (success) {
          this.spinner = true
          this.active = false
          this.$axios
            .post('user/generate-secret', {
              phone_number: this.phonenumber,
            })
            .then(() => {
              this.spinner = false
              this.active = true
              this.$router.push('/otp-code')
            }).catch(error => {
              this.$swal(error.message)
            })
        }
      })
    },
  },
}
/* eslint-disable global-require */
</script>
  <style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
  .heading{
    font-size: 25px;
  }
</style>
